
/*--------------------------------------------------------------

# General

--------------------------------------------------------------*/
body {
  font-family: "Open Sans","Noto Sans KR", sans-serif;
  color: #444444;
}

a {
  color: #106eea;
}

a:hover {
  color: #3b8af2;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto","Noto Sans KR", sans-serif;
}

/*--------------------------------------------------------------

# Preloader

--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #fff;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #106eea;
  border-top-color: #e2eefd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------

# Back to top button

--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background: #106eea;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #3284f1;
  color: #fff;
}

/*--------------------------------------------------------------

# Disable aos animation delay on mobile devices

--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}


/*--------------------------------------------------------------

# Header

--------------------------------------------------------------*/
#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  padding: 25px 0;
  top: 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

}

#header.header-scrolled {
  top: 0;
  padding: 15px;
}

#header .logo {
  font-size: 32px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #222222;
}

#header .logo a span {
  color: #106eea;
}

#header .logo img {
  max-height: 40px;
}

#header .btn {
  font-family: "Roboto", sans-serif;
  transition: .3s;
  padding: 0 10px;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  color: #828282;
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  text-decoration: none;
}

#header .btn:hover {
  background: #247cf0;
  color:#fff;
}

@media (max-width: 992px) {
  #header {
    padding: 15px;
    top: 0;
  }
  #header .logo {
    font-size: 28px;
  }
  #header .logo img {
  max-height: 30px;
}
}


/*--------------------------------------------------------------
# Header util 
--------------------------------------------------------------*/
.header-util {
  padding-left: 20px;
}

.header-util a {
  color: #222222;
  padding-left: 6px;
  display: inline-block;
  line-height: 1px;
  transition: 0.3s;
  font-size: 14px;
  font-family:"Noto Sans KR";
  font-weight:400;
}

.header-util a:hover {
  color: #34b7a7;
}

.i_user {background:#fff url(assets/img/i_user.png) no-repeat 0 0; text-indent:-9999px; width:24px; height:24px;}

@media (max-width: 992px) {
  .header-util {
    padding: 0 0 0 0;
  }
}


/*--------------------------------------------------------------

# Navigation Menu

--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul {
  display: flex;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #222222;
  transition: 0.3s;
  font-size: 15px;
  font-weight: 400;
  padding: 0 3px;
  font-family: "Open Sans","Noto Sans KR", sans-serif;
}

.nav-menu > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #106eea;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.nav-menu a:hover:before, .nav-menu li:hover > a:before, .nav-menu .active > a:before {
  visibility: visible;
  width: 100%;
}

.nav-menu a:hover, .nav-menu .active > a, .nav-menu li:hover > a {
  color: #106eea;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 26px;
  top: calc(100% + 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #062b5b;
}

.nav-menu .drop-down ul a:hover, .nav-menu .drop-down ul .active > a, .nav-menu .drop-down ul li:hover > a {
  color: #106eea;
}

.nav-menu .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: "\eaa0";
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: "\ea9d";
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #222222;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #444444;
  padding: 10px 20px;
  font-weight: 400;
  outline: none;
}

.mobile-nav a:hover, .mobile-nav .active > a, .mobile-nav li:hover > a {
  color: #106eea;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(9, 9, 9, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------

# Intro Section

--------------------------------------------------------------*/
#intro {
  height: 100%;
  width:100%;
  position: relative;
  padding-top:93px;

}

#intro > img {
  max-width:100%;
  height:auto;
  vertical-align:middle;
}


@media (max-width: 992px) {
  #intro {
    padding-top: 58px;
  }
}


/*--------------------------------------------------------------

# Sections General

--------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f6f9fe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #106eea;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 {
  margin: 15px 0 0 0;
  font-size: 32px;
  font-weight: 700;
}

.section-title h3 span {
  color: #106eea;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------

# Breadcrumbs

--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: #f1f6fe;
  min-height: 40px;
  margin-top: 94px;
}

@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 58px;
  }
}

.breadcrumbs h2 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/*--------------------------------------------------------------

# Banner Services

--------------------------------------------------------------*/
.banner-services {
  display:block;
  height:90px;
  background:#0062df;
}



/*--------------------------------------------------------------

# Services

--------------------------------------------------------------*/
.services {
  background:#f6f9fe;
}
.services .icon-box {
  text-align: center;
  border: 1px solid #e2eefd;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
  background: #fff;
  width:100%;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #f1f6fe;
  border-radius: 4px;
  border: 1px solid #deebfd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transition: ease-in-out 0.3s;
}

.services .icon-box .icon i {
  color: #3b8af2;
  font-size: 28px;
  transition: ease-in-out 0.3s;
}

.services .icon-box h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 20px;
}

.services .icon-box h4 a {
  color: #222222;
  transition: ease-in-out 0.3s;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  border-color: #fff;
  box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
}

.services .icon-box:hover h4 a, .services .icon-box:hover .icon i {
  color: #106eea;
}

.services .icon-box:hover .icon {
  border-color: #106eea;
}




/*--------------------------------------------------------------
# list
--------------------------------------------------------------*/
.list #tab {
  padding: 0;
  margin: 0 auto 5px auto;
  list-style: none;
  border-radius: 50px;
}

.list #tab li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px 8px 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #444444;
  margin-bottom: 5px;

}

.list #tab li:hover, .list #tab li.filter-active {
  color: #106eea;
}

.list #tab li.filter-active {
  color: #106eea;
  border-bottom:2px solid #106eea;
}


.list #tab li:last-child {
  margin-right: 0;
}

.list-title h2 {
  font-size: 16px;
  margin:10px 0 15px 0;
  padding:0;
  line-height: 1;
  font-weight: 400;
}

/*--------------------------------------------------------------

# Footer

--------------------------------------------------------------*/
#footer {
  background: #fff;
  padding: 0 0 20px 0;
  color: #444444;
  font-size: 14px;
}



#footer .footer-top {
  padding: 50px 0 0 0;
  background: #fff;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #106eea;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #777777;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}



#footer .copyright {
  text-align: center;
  float: left;
}


@media (max-width: 768px) {
  #footer .copyright {
    float: none;
    text-align: center;
    padding: 2px 0;
  }

  #footer .footer-top .footer-contact {
  text-align: center;
  }

}

/*--------------------------------------------------------------
# table_style
--------------------------------------------------------------*/

.list_tb01 {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.list_tb01 th,
.list_tb01 td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}

.list_tb01 thead th {
  background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: .825rem;
    font-weight: 400;
    letter-spacing: .08em;
    color: #95aac9;
    border-bottom-width: 1px;
	text-align:center;
	white-space: nowrap;
}

.list_tb01 tbody {border-bottom:1px solid #dee2e6; white-space: nowrap;}

.list_tb01 tbody th {
  background-color: #f9fbfd;
    text-transform: uppercase;
    font-size: .825rem;
    font-weight: 400;
    letter-spacing: .08em;
    color: #95aac9;
    border-bottom-width: 1px;
	text-align:center;
}

.list_tb01 a, .list_tb01 a:hover {color:#333;}

/* 텍스트형 게시판_공지사항 */
.list_tb02 {margin:0 0 1.5em 0;line-height:1.333;overflow:hidden;}
.list_tb02 .lst1{list-style:none;margin:0;padding:0;border-top:2px solid #57a2e3;border-bottom:1px solid #e1e1e1;overflow:hidden;}
.list_tb02 .li1{margin:0;padding:0;border-top:1px solid #e1e1e1;overflow:hidden;}
.list_tb02 .li1:first-child{border:0;}
.list_tb02 .li1 .a1{display:block;padding:1.333em 0;text-decoration:none;overflow:hidden;}
.list_tb02 .li1 .a1:hover .t1, 
.list_tb02 .li1 .a1:hover .t2{text-decoration:underline;}
.list_tb02 .li1 .f1{float:left;width:102px;margin:0 3% 0 0;}
.list_tb02 .li1 .t1{display:block;color:#333;font-weight:600;font-size:1em;}
.list_tb02 .li1 .t2{display:block;position:relative;max-height:4em;margin:.6em 0 0;color:#666;font-size:.925em;overflow:hidden;}
.list_tb02 .li1 .t2:after{content:'…';display:block;position:absolute;right:0;top:2.666em;width:3em;height:1.333em;overflow:hidden;text-indent:-9999px;
	background:linear-gradient(to right, rgba(255,255,255,0) 0, #fff 100%);
}
.list_tb02 .li1 .wrap1t3{display:block;margin:.666em 0 0;color:#666;}
.list_tb02 .li1 .t3{display:inline;padding:0 .5em 0 .6em;font-size:.925em; font-weight:300;}
.list_tb02 .li1 .t3:first-child{display:inline;padding-left:0;background:transparent;}


/*  팝업테이블 */
.list_tb03 {width:100%; border-top:1px solid #307db4;}
.list_tb03 th{ background:#f9fbfd;  border-bottom:1px solid #dadada;border-left:1px solid #dadada; border-right:1px solid #dadada;padding: 0.75rem;text-align:left;height:25px;font-size: .925rem;font-weight:400;}
.list_tb03 td{border-bottom:1px solid #dadada; border-left:1px solid #dadada;border-right:1px solid #dadada;padding: 0.75rem;font-size: .925rem;}
.list_tb03 td img {vertical-align:middle;}
.list_tb03 td a:hover {text-decoration:underline;}
.list_tb03 td.al {text-align:left;} .tbl_type02 td.ar {text-align:right;}


/* 상세뷰1 */
.bbs1view1{clear:both;position:relative;border-top:0 solid #678;border-bottom:1px solid #678;}
.bbs1view1>.h1:first-child{display:block; margin:0;padding:.666em 0;color:#333;font-weight:700;font-size:1.1em;line-height:1.333;width:80%;}
.bbs1view1 .info1{margin:0 0 -1px 0;border-top:1px solid #eaecee;border-bottom:1px solid #eaecee;background:#fff;overflow:hidden;}
.bbs1view1 .info1 .dl1{list-style:none;margin:0;padding:.333em 0;color:#333;font-size:.925em;overflow:hidden;}
.bbs1view1 .info1 .dt1{float:left;width:0;margin:0;padding:3px 5px 3px 1px;font-size:.925em; overflow:hidden;text-indent:-9999px;}
.bbs1view1 .info1 .dd1{float:left;margin:0;padding:3px 10px 0 0px;}
.bbs1view1 .info1 .dt1:first-child{background:transparent;}
.bbs1view1 .info1 .dt1:first-child+.dd1{padding-right:15px;}
.bbs1view1 .info1 .dt1.show{width:auto;margin-right:0px;padding-left:5px;text-indent:0;}

.bbs1view1 .substance{ padding:2em 0;border-top:1px solid #abc; font-size:1em;line-height:1.5em; clear:both;}

.viewPreview { border-top:1px solid #d9d9d9; margin-top:10px; line-height:140%; padding:0; }
.viewPreview li { border-bottom:1px solid #d9d9d9; padding:17px 50px 16px 20px; color:#0d0d0d; list-style:none; }
.viewPreview li em { display:inline-block; margin-right:10px; height:100%; padding-left:20px;}
.viewPreview li .in { display: inline-block; vertical-align:middle; height:25px;color:#666;line-height:25px;text-overflow:ellipsis;white-space:nowrap;word-wrap:normal;overflow:hidden;}
.viewPreview li .in a {color:#333;}
.viewPreview li .in a:hover {text-decoration:underline;}


.i-pagprev {background: url(assets/img/icon_prev.png) 0 3px no-repeat;}
.i-pagnext {background: url(assets/img/icon_next.png) 0 3px no-repeat;}


.board_new{
	float:left;
	width:16px;
	height:16px;
	margin:2px 5px 1px 0;
	border-radius:50%;background:#e44b3a;color:#fff;
	font:normal 10px/16px Helvetica, Arial, sans-serif;
	text-transform:uppercase;
	vertical-align:middle;
	letter-spacing:16px;
	text-indent:4px;
	overflow:hidden;
}

/*--------------------------------------------------------------
# inner-page
--------------------------------------------------------------*/

.inner-page {border-bottom:1px solid #ddd;}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  padding: 23px 20px 21px 40px;
  border-top:1px solid #dcdcdc;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #106eea;
  font-size: 18px;
  font-weight: 500;
}

.faq .faq-list i {
  font-size: 18px;
  position: absolute;
  left: -25px;
  top: 6px;
}

.faq .faq-list p {
  margin-bottom: 20px;
  font-size: 15px;
  padding:30px 0 0;

}

.faq .faq-list a.collapse {
  color: #106eea;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #106eea;
}

.faq .faq-list a.collapsed i::before {
  content: "\eab2" !important;
}

/*--------------------------------------------------------------
# Note list
--------------------------------------------------------------*/
.note {
  padding: 0;
}

.note .note-list {
  padding: 0;
  list-style: none;
}

.note .note-list li {
  padding: 0 0 10px 25px;
  border-top:1px solid #dcdcdc;
  position:relative;
}

.note .note-list a {
  display: block;
  position: relative;
  font-family: #106eea;
  font-size: 16px;
  font-weight: 500;
  padding-left:30px;
}

.note .note-list i {
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 5px;
}

.note .note-list .ckbox {
  font-size: 16px;
  position: absolute;
  left: 5px;
  top: 15px;
}

.note .note-list p {
  margin-bottom: 10px;
  font-size: 15px;
  padding:10px 0 0;

}

.note .note-list a.collapse {
  color: #106eea;
}

.note .note-list a.collapsed {
  color: #343a40;
}

.note .note-list a.collapsed:hover {
  color: #106eea;
}

.note .note-list a.collapsed i::before {
  content: "\eab2" !important;
}


/*--------------------------------------------------------------
# common
--------------------------------------------------------------*/

.w-10 {
  width: 10% !important;
}

/*--------------------------------------------------------------
# popup
--------------------------------------------------------------*/
.pop_head {height:47px;background:#fff;position:relative;z-index: 1;}
.pop_content {padding:50px 20px 50px 20px;}
.titlewrap{margin:0 auto;padding:0 10px 0 0; height:47px; background:#106eea;}
.pop_title {font-size:16px; color:#fff; font-weight:bold; padding:12px 0px 10px 20px;}

.hidden_text {display:none;}


.btn-xs {
  padding  : .25rem .4rem;
  font-size  : .875rem;
  line-height  : .5;
  border-radius : .2rem;
}

/* for skeleton */

@keyframes aniVertical {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.datagrid__row {
  animation: aniVertical 3s ease;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  opacity: 0;

  
}

.datagrid__row:nth-child(2) {
  animation-delay: .5s;
}

.datagrid__row:nth-child(3) {
  animation-delay: 1s;
}

.datagrid__loader { 
  border-radius: 8px;
  background: #d9d9d9;
  height: 12px;
}


/* m_layout */
#contentsWrap {clear:both; padding:0; background-color:#fff; position:relative;display:block;} 
#contents {margin-top:100px; padding:10px;}
#m_footerWrap {clear:both; text-align: center; background:#4d5969; margin-top:-17px; } 

.footercon {color:#fff; font-size:13px; padding:20px 0;}
.topwrap {border-bottom:1px solid #737d8a;  padding:10px 0;  }
.topwrap a {font-family: "Poppins";color:#fff; font-size:13px; }

/* m_contents */
.holdcoin_box {clear:both; position:relative;}
.holdcoin_box .c_tit {background:#1196fd url(assets/img/holdcoin_box.jpg) no-repeat right 0; font-size:15px; border:1px #2381d9 solid; border-radius:3px 3px 0 0; padding:25px 0 6px 20px; color:#fff;height:114px;}
.holdcoin_box .c_tit span {display:block; color:#b9e0ff; font-weight:400; font-size:13px;}

.basic_box {position:relative; margin:10px 0; border:1px solid #dddddd; border-radius:5px; padding:20px 20px; min-height:220px;}
.basic_box .titwrap {padding:30px 0 0 0; font-size:16px;}

.deal {background:#fff url(assets/img/i_basicbox01.png) no-repeat 20px 20px;}
.sale {background:#fff url(assets/img/i_basicbox02.png) no-repeat 20px 20px;}

.i_refresh01 {display:inline-block; background:url(assets/img/i_refresh01.png) no-repeat 0 2px; width:20px; height:20px; text-indent:-9999px;}
.i_refresh02 {display:inline-block; background:url(assets/img/i_refresh02.png) no-repeat 0 2px; width:20px; height:20px; text-indent:-9999px;}

.coin {border:1px #ccc solid; border-radius:0 0 5px 5px; border-top:none; padding:0; background-color:#fff; display:block; position:relative;}
.coin ul {padding:5px 15px 0 15px;}
.coin_list {clear:both; padding:0;}
.coin_list li{clear:both;list-style:none; border-bottom:1px solid #ddd; padding-top:20px;}
.coin_list li:last-child {border-bottom:none;}
.coin_list .type {float:left; width:49.9%;  color:#1f3f78; font-size:15px; font-weight:600;}
.coin_list .type span {display:block; font-size:12px; font-weight:400;}
.coin_list .type .date {color:#999;}
.coin_list .num {font-weight:600; font-size:15px; text-align:right;}
.coin_list .num span {display:block; color:#999; font-size:12px; font-weight:400;}

.empty_text {text-align:center; color:#999; font-size:13px; height:35px; padding:10px 0;}

.btnbox {position:relative;padding:5px 5px; text-align:center;}
.btnbox a.submit {background:#007bff; color: #fff; width:100%; display:inline-block; line-height:32px; font-size:13px; font-weight:400; border-radius:3px;}
.btnbox a.submit .icon {background:url(assets/img/i_submit.png) no-repeat 0 2px; padding-right:20px; }

/* guide */
.guide .guide-list {padding: 0;list-style: none;}
.guide .guide-list a {display: block;position: relative;font-size: 14px; font-weight:500; color: #343a40;border-bottom:1px solid #dcdcdc; padding:10px 10px 10px 32px; background:url(assets/img/i_guide.png) no-repeat 10px 13px; }
.guide .guide-list i {font-size: 18px;position: absolute;right: 10px;top: 10px;}
.guide .guide-list ul {margin-bottom: 20px;font-size: 14px;padding:10px 10px;}
.guide .guide-list ul li {position:relative; padding:0 20px 10px 10px; line-height:24px;}
.guide .guide-list ul li:before {  content: ''; display: block; position: absolute;left: 5px;top: 10px; width: 2px; height: 2px; border-radius: 100%; background: #000;}
.guide .guide-list ul li:last-child {border-bottom:none;}
.guide .guide-list a.collapse {color: #343a40;}
.guide .guide-list a.collapsed {color: #343a40;}
.guide .guide-list a.collapsed i::before {content: "\eab2" !important;}

.txt_blue{color:#007bff !important;}
.txt_red{color:#ff5252 !important;}
.txt_green{color:#4a8e25 !important;}
.txt_gray{color:#999 !important;}

@media (max-width: 992px) {

	#contents {margin-top:70px;}

}

/* 내지갑 */
.mybox {background:#fff url(assets/img/i_mybox.png) no-repeat 20px 20px;}
.info_list {clear:both; padding:0; }
.info_list li{clear:both;list-style:none; border-bottom:1px solid #ddd; padding-top:20px;}
.info_list li:last-child {border-bottom:none;}
.info_list .tleft {float:left; width:49.9%;  color:#777; font-size:15px; font-weight:400;}
.info_list .tright {font-weight:600; font-size:15px; text-align:right;}

.infobox {border:1px #efefef solid; border-radius:5px; background-color:#f9f9f9; display:block; position:relative; list-style: none; padding:10px 10px; margin-bottom:0;}
.infobox li {position:relative; border-bottom:1px solid #ddd; padding:10px 10px;}
.infobox li:last-child {border-bottom:none;}
.infobox li span {display:inline-block; width:100%; color:#999; font-size:15px; padding-bottom:7px;}
.infobox li p {margin-bottom:5px;}
.infobox .tl {float:left; width:49.9%;   font-size:15px; font-weight:400;}
.infobox .tr {font-weight:400; font-size:15px; text-align:right;}


/* 플로팅 메뉴 */
.floating {position: fixed;right: 10px; bottom: 10px;text-align: center;transition: 0.5s; z-index: 1000;}
.floating ul li{list-style:none;}
.floating-menu { background: #3a83f9;width: 50px;height: 50px;border-radius: 50%;margin-bottom: 15px;box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);}
.floating-menu a {color: #fff;font-size: 12px;padding-top: 14px;display: block;} 
.hide li:first-child a {padding-top:0px;}
.floating-menu.active a.open::after {content: "\eee1";font-family: IcoFont; color: #fff;font-weight: 500;font-size: 15px; }
.floating-menu a.open::after {content: "\eacb";font-family: IcoFont; color: #fff;font-weight: 600;font-size: 15px;}
.floating-menu .floatingHide {margin-top: -230px;padding:0;}
.floating-menu .floatingHide>li {background: #3a83f9;width: 50px;height: 50px;border-radius: 50%;margin-bottom: 15px;box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);cursor: pointer;list-style:none;}
.floating-menu .floatingHide>li a.br {display: block;padding-top: 10px;}
.floating-menu .floatingHide>li a.no_br {display: block; padding-top: 17px;}
.floating-menu .floatingHide {display: none;}

.pagenum {
  width: 45px !important;
  float: left;
  margin-right: 5px;
}

/* 사용자동의 */
.privacy {border:1px #efefef solid; border-radius:5px; padding:20px 15px; margin-bottom:0; height:220px; overflow-y:scroll;}
.privacy p {font-size:14px; line-height:21px;}

/* loader */
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(158, 158, 158, 0.2);
  border-right: 1.1em solid rgba(158, 158, 158, 0.2);
  border-bottom: 1.1em solid rgba(158, 158, 158, 0.2);
  border-left: 1.1em solid rgb(70, 70, 70);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
